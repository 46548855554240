import * as React from "react";
import { Box, Text, Heading, Center } from "@chakra-ui/react";
import Layout from "../components/layout";
const NotFoundPage = () => {
  return (
    <Layout>
      <Center h="80px" color="black">
        <Heading as="h1">404 Not Found</Heading>
      </Center>
      <Box textAlign="center">
        <Text fontSize="l">
          Sorry the page you looking for has moved or does not exist. Head to
          the home page to find the page you want.
        </Text>
      </Box>
    </Layout>
  );
};

export default NotFoundPage;
